import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-do_nf42zsgm43jkimb6usetkg5lg4/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-do_nf42zsgm43jkimb6usetkg5lg4/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/@next+third-parties@14.2.15_next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-do_nf42zsgm43jkimb6usetkg5lg4/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@14.2.15_@babel+core@7.25.8_@opentelemetry+api@1.9.0_react-dom@18.3.1_react@18.3.1__react@18.3.1_sass@1.80.3/node_modules/next/font/google/target.css?{\"path\":\"src/app/[locale]/layout.tsx\",\"import\":\"Commissioner\",\"arguments\":[{\"subsets\":[\"latin\",\"cyrillic\"],\"weight\":[\"400\",\"600\"],\"style\":[\"normal\"]}],\"variableName\":\"CommissionerFont\"}");
;
import(/* webpackMode: "eager" */ "/app/src/styles/app.scss");
